import React from "react";
import { Link } from "react-router-dom";

import './404.css';

import { useLanguage } from "../LanguageContext";


function Handle404() {

    const {language} = useLanguage();

    const lang = require(`../languages/${language}.json`);
    const lang404 = lang.page404;

    return(
        <section className="page-404">
            <div className="sub-div-404">
                <h2>
                    <strong>{lang404.noticeH}</strong>
                </h2>
                <h3><strong>{lang404.notice}</strong></h3>
                <h3><strong>{lang404.notice2}</strong></h3>
                <div className="button-holder">
                    <Link className="button-404" to={'/'}>{lang404.return}</Link>
                    <Link className="button-404" to={'/Shop'}>{lang404.shop}</Link>
                </div>
            </div>
        </section>
    )
}

export default Handle404;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Video from '../Resources/Krasula Video Web.mp4';
import './Home.css';
import ProductListH from "./components/ProductList";
import { useLanguage } from "../LanguageContext";

import FlowerHand from '../Resources/FlowerHand.svg';
import Flower from '../Resources/Flower.svg';
import WorldHeart from '../Resources/WorldHeart.svg';
import YingYang from '../Resources/YingYangFlower.svg';
import Dove from '../Resources/Dove.svg';

import VideoThumbnail from '../Resources/Webiste Video Thumbnail.png';

import BlogCover from '../Resources/Blog cover.png';
import FAQCover from '../Resources/FAQ cover.png';
import ContactCover from '../Resources/ContactCover.png';

function Home({TopProducts, setCartItemCount, loading}) {

    const {language} = useLanguage();
	// Replace placeholders with dynamic content
	const lang = require(`../languages/${language}.json`); // Deep copy to avoid modifying the original template

	const introFeatures = lang.home.intro.features;

	const FeatureIcons = {1: FlowerHand, 2: WorldHeart, 3: Flower, 4: Dove, 5: YingYang}
	
	const [selectedCover, setSelectedCover] = useState('/Blog');

	return (
		<div className="page-home">
		
			<div className="home-cover" onContextMenu={(e) => e.preventDefault()}>
				<h1>{lang.home.title}</h1>
				<video preload="metadata" autoPlay muted loop poster={VideoThumbnail}>
				<source src={Video} type="video/mp4" />
				</video>
			</div>

			<section className="intro-home">
				<h1 className="heading-one">{lang.home.intro.heading}</h1>
				{introFeatures.map((el,index)=> (
					<div className="intro-feature" key={`featrue${index}`}>
						<img width={60} src={FeatureIcons[el.id]} alt="icon"/>
						<h2>{el.title}</h2>
						<p>{el.description}</p>
					</div>
				))}
			</section>
			
			
			{/* Highlighted Products Section */}
			<ProductListH loading={loading} setCartItemCount={setCartItemCount} title={lang.home.highlightedProducts.title} products={TopProducts} />
		
			<section className="call-to-action-link-wraper">
				<img className={selectedCover === '/Blog'? "" : 'hidden'} src={BlogCover} alt="action-cover"/>
				<img className={selectedCover === '/About'? "" : 'hidden'} src={VideoThumbnail} alt="action-cover"/>
				<img className={selectedCover === '/FAQ'? "" : 'hidden'} src={FAQCover} alt="action-cover"/>
				<img className={selectedCover === '/Contact'? "" : 'hidden'} src={ContactCover} alt="action-cover"/>
					<div className="call-to-action-links">
					<h1 className="heading-one">{lang.home.callToAction.heading}</h1>
						{Object.entries(lang.home.callToAction.features).map(([key,el])=>(
							el.type === "text"? (
								<p key={key}>{el.content}</p>
							) : (
								<Link className="call-to-action-link" onMouseEnter={() => setSelectedCover(el.content.href)} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} key={key} to={el.content.href}>
									{el.content.text}
								</Link>
							)
						))}
					</div>
			</section>
			
		</div>
	);
}

export default Home;

import './App.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';

import Home from './Home/Home';
import AboutMe from './AboutMe/AboutMe';
import Contact from './ContactMe/Contact';
import Cart from './Cart/Cart';
import Shop from './Shop/Shop';
import Blog from './Blog/Blog';
import FAQ from './FAQ/FAQ';
import ProductPage from './ProducPage/ProductPage';
import { loadCartFromLocalStorage } from './Cart/CartLogic';
import { useLanguage } from './LanguageContext';
import Handle404 from './404Handle/404';
import LanguagePick from './Components/LanguagePick';

import callApi from './BackendCall/BackedCall';
import Sorter from './SortingLogic/SortingLogic';
import SocialIconList from './Components/socailIcons';

function App() {

	const [ShowCart, setShowCart] = useState(false);
	const [Products, setProducts] = useState([]);
	const [Loading, setLoading] = useState(true);
	const [TopProducts, setTopProducts] = useState([]);
	const [cartItemCount, setCartItemCount] = useState(0);
	const [TopicList, setTopicList] = useState([]);
	const [FAQList, setFAQList] = useState([]);
	const [BestSellers, setBestSellers] = useState([]);
	const [LatestBlog, setLatestBlog] = useState([]);
	const {language} = useLanguage();
	
	const ToggleCart = () => {
		const state = !ShowCart;
		setShowCart(state);
		document.body.style.overflow = state ? 'hidden' : 'auto';
	}
		
	useEffect(() => {
		
		async function fetchProducts() {
			const response = await callApi('/products/getProductList.php','POST', {lang: language});
			if(response.error){
				console.error(response.error);
			}else{
				setProducts(response.data);
				const topProductsArray = new Array(3);
				const BestS = [];
				response.data.forEach((el) => {
					if (el.bestSeller) {
						BestS.push(el);
					}
					if (el.top === '1') {
						topProductsArray[0] = el;
					}
					if (el.top === '2') {
						topProductsArray[1] = el;
					}
					if (el.top === '3') {
						topProductsArray[2] = el;
					}
				});
				setBestSellers(BestS);
				setTopProducts(topProductsArray);
			}
			return response.loading;
		} 
		
		async function getTopics() {
			const response = await callApi('/blog/getTopics.php', 'POST', {lang: language});
			if(response.error){
				console.error('Error fetching topics:', response.error)
			}else{
				const sortedData = Sorter.sortByDateDescending(response.data);
				setTopicList(sortedData);
				setLatestBlog(sortedData[0]);
			}
			return response.loading;
		}
		
		async function getFAQ(){
			const response = await callApi('/FAQ/getFAQ.php', 'POST', {lang: language});
			if(response.error){
				console.error('Error fetching FAQ:', response.error);
			}else{
				setFAQList(...response.data);
			}
			return response.loading;
		}
		
		const fetchSomeData = async () => {
			setCartItemCount(loadCartFromLocalStorage().length);
				const stage1 = await fetchProducts();
				const stage2 = await getTopics();
				const stage3 = await getFAQ();
				
				setLoading(stage1 || stage2 || stage3);
				
				document.body.style.overflow = 'visible';
		};

		setLoading(true);
		window.scrollTo(0,0);
		document.body.style.overflow = 'hidden'
		
		const fetchData = async () => {
			await fetchSomeData();
		};
	
		fetchData();

	}, [language]);


	return (
		<Router>
			<div className="App">
				<Cart Shown={ShowCart} ToggleCart={ToggleCart} cartItemCount={cartItemCount} setCartItemCount={setCartItemCount} productList={Products} />
				<header className='header-container'>
					<span className='socailSpan'>
						<SocialIconList fill={'#ffffff'} />
					</span>
					<LanguagePick />
				</header>
				<NavBar ToggleCart={ToggleCart} cartItemCount={cartItemCount}/>
				<Routes>
					<Route path='/' element={<Home TopProducts={TopProducts} setCartItemCount={setCartItemCount} loading={Loading} />}></Route>
					<Route path='/About' element={<AboutMe/>}></Route>
					<Route path='/Shop' element={<Shop setCartItemCount={setCartItemCount} Products={Products} />}></Route>
					<Route path='/Contact' element={<Contact/>}></Route>
					<Route path='/Product' element={<ProductPage productList={Products} setCartItemCount={setCartItemCount} />}/>
					<Route path='/Blog' element={<Blog TopicList={TopicList} />}></Route>
					<Route path='/FAQ' element={<FAQ setCartItemCount={setCartItemCount} FAQList={FAQList} BestSellers={BestSellers} loading={Loading} />}></Route>
					<Route path='*' element={<Handle404 />}></Route>
				</Routes>
				<Footer products={Products} LatestBlog={LatestBlog}/>
			</div>
		</Router>
	);
}

export default App;

import React, { useState } from "react";
import './Contact.css';
import BackGround from '../Resources/ContactFormBackground.png';
import GearSVG from '../Resources/Gear.svg';
import SuccessSVG from '../Resources/Approve.svg';
import FailedSVG from '../Resources/Exit.svg';
import PhoneHandle from '../Resources/phone-handle.svg';
import { useLanguage } from "../LanguageContext";
import SocialIconList from "../Components/socailIcons";

function Contact() {

    // State to manage user input
    const [userInfo, setUserInfo] = useState({ name: '', email: '', message: '' });
    const [EmailingStages, setEmailingStages] = useState({sending: false, 
                                                          sent: false,
                                                          failed: false});

    const {language} = useLanguage();
    const  lang = require(`../languages/${language}.json`);
    const contactLang = lang.contact;

    // Function to handle user input
    function handleUserInput(e) {
        const { name, value } = e.target;
        setUserInfo(prevState => ({ ...prevState, [name]: value }));
    }

    // Function to send email (you can implement your logic here)
    function sendEmail() {
        setEmailingStages({sending: true, sent:false, failed:false});

        const data = new FormData();
		data.append('name', userInfo.name);
		data.append('email', userInfo.email);
        data.append('message', userInfo.message);

        fetch('https://krasula-ns.com/BackendKrasula/Contact.php', {
            method: 'POST',
            body: data
        })
        .then(response => {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                return response.json();
            } else {
                return response.text();
            }
        })
        .then(data => {
            if(data.trim() === 'success'){
                setEmailingStages({sending: false, sent:true, failed:false});
            }else{
                setEmailingStages({sending: false, sent:false, failed:true});
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setEmailingStages({sending: false, sent:false, failed:true});
        });
    }

    return (
        <div className="page-contact">
            <section className="contact-background">
                <img src={BackGround} alt="background" />
            </section>
            {EmailingStages.sending? (
                <div className="contact-form response">
                    <p>{contactLang.stage.sending}</p>
                    <img className="contact-gear" style={{width: '120px'}} src={GearSVG} alt="gear" />
                </div>
            ) : EmailingStages.sent? (
                <div className="contact-form response">
                    <p>{contactLang.stage.success}</p>
                    <img style={{width: '120px'}} src={SuccessSVG} alt="success" />
                </div>
            ) : EmailingStages.failed? (
                <div className="contact-form response">
                    <p>{contactLang.stage.fail}</p>
                    <img style={{width: '120px'}} src={FailedSVG} alt="failed" />
                </div>
            ) : (
            <form className="contact-form">
                <h1>{contactLang.title}</h1>
                <SocialIconList/>
                <p className="phone-contact"><img width={20} src={PhoneHandle} alt="phone"/>+38163576400</p>
                <input
                    id="contact-name"
                    type="text"
                    name="name"
                    value={userInfo.name}
                    onChange={handleUserInput}
                    required
                    placeholder={contactLang.label.name}
                />
                <input
                    id="contact-email"
                    type="email"
                    name="email"
                    value={userInfo.email}
                    onChange={handleUserInput}
                    required
                    placeholder={contactLang.label.email}
                />
                <textarea
                    id="message"
                    name="message"
                    value={userInfo.message}
                    onChange={handleUserInput}
                    required
                    placeholder={contactLang.label.message}
                ></textarea>
                <button className="contact-button" type="button" onClick={sendEmail}>
                    {contactLang.button}
                </button>
            </form>

            )}
        </div>
    );
}

export default Contact;

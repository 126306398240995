import React from "react";

import './FAQ.css';

import LoadingDiv from "../Components/Loading";

import { useLanguage } from "../LanguageContext";
import ProductListH from "../Home/components/ProductList";
import FAQcover from "../Resources/FAQ cover.png";

function FAQ({setCartItemCount, FAQList, BestSellers, loading}){

    const {language} = useLanguage();
    const lang = require(`../languages/${language}.json`);
    const FAQlang = lang.FAQ;

    return (
        <div className="faq-page">
            <LoadingDiv Loading={loading} />
            <img className="faq-cover" src={FAQcover} alt="logo" />
            <ul className="q-section">
                {FAQList.length !== 0? (FAQList.map((el,index) => (
                    <li key={`faq${index}`}>
                        {FAQlang.questionLabel} {index+1}:
                        <ul>
                            <li>{el.q}</li>
                            <li>{el.a}</li>
                        </ul>
                    </li>
                ))) : (
                    <li>{FAQlang.loading}</li>
                )}
            </ul>
            <ProductListH loading={loading} setCartItemCount={setCartItemCount} title={FAQlang.bestSeller} products={BestSellers} />
        </div>
    )
}

export default FAQ;
class Sorter {
    // Function to convert date string to sortable format
    static convertToDateSortableFormat(dateString) {
        const [day, month, year] = dateString.split('.');
        return new Date(`${year}-${month}-${day}`);
    }

    // Sort function for ascending order of dates
    static sortByDateAscending(array) {
        return array.sort((a, b) => {
            const dateA = Sorter.convertToDateSortableFormat(a.date);
            const dateB = Sorter.convertToDateSortableFormat(b.date);
            return dateA - dateB;
        });
    }

    // Sort function for descending order of dates
    static sortByDateDescending(array) {
        return array.sort((a, b) => {
            const dateA = Sorter.convertToDateSortableFormat(a.date);
            const dateB = Sorter.convertToDateSortableFormat(b.date);
            return dateB - dateA;
        });
    }

    // Sort function for sale property (true values first)
    static sortBySale(array) {
        return array.sort((a, b) => {
            // Products with sale=true should come first
            if (a.sale && !b.sale) {
                return -1;
            }
            if (!a.sale && b.sale) {
                return 1;
            }
            // For other cases, maintain original order
            return 0;
        });
    }
}

export default Sorter;

import React from "react";

import './Loading.css';

import Logo from '../Resources/Krasula LOGO.png';
import Gear from '../Resources/Gear.svg';
import {useLanguage} from '../LanguageContext';

function LoadingDiv({Loading}){

    const {language} = useLanguage();

    const text = {srb: 'UČITAVA SE...', eng: 'LOADING...'}

    return(
        <div className={Loading? 'loading' : 'loading hidden'}>
            
            <p>
                <img alt="logo" className="logo-loading" src={Logo} />
                <img alt="gear" className="gear-loading" src={Gear} />
                {text[language]}
            </p>
            
        </div>
    )
}

export default LoadingDiv;
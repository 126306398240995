import React from "react";

import "./socialIcons.css";

import {ReactComponent as FaceBook} from "../Resources/facebookSvg.svg";
import {ReactComponent as Instagram} from "../Resources/InstagramSVG.svg";
import {ReactComponent as TikTok} from "../Resources/tiktokSvg.svg";

function SocialIconList({fill}){
    return (
        <section className="social-icon-list" style={{color: fill}}>
            <a href="https://www.facebook.com/profile.php?id=100077056084612" rel="noreferrer" target="_blank">
                <FaceBook width={25} fill={fill} />
            </a>
            <a href="https://www.instagram.com/krasula_prirodna_nega/" rel="noreferrer" target="_blank">
                <Instagram width={25} fill={fill} />
            </a>
            <a href="https://www.tiktok.com/@krasulaprirodnanega" rel="noreferrer" target="_blank">
                <TikTok width={25} fill={fill} />
            </a>
        </section>
    )
}

export default SocialIconList;


async function fetchApi(url, method = 'GET', requestData = null) {
    const options = {
        method,
        headers: {},
        body: null,
    };

    if (method === 'POST' && requestData !== null && typeof requestData === 'object') {
        const formData = new FormData();
        for (const key in requestData) {
            formData.append(key, requestData[key]);
        }
        options.body = formData;
    } else if (method === 'POST' && requestData !== null && !(requestData instanceof FormData)) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(requestData);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}

async function callApi(url, method = 'GET', requestData = null) {
    let data = null;
    let loading = true;
    let error = null;

    try {
        data = await fetchApi(`https://krasula-ns.com/backend${url}`, method, requestData);
    } catch (err) {
        error = err;
    } finally {
        loading = false;
    }

    return { data, loading, error };
}

export default callApi;

import React, { useEffect, useState } from "react";

import './ProductPage.css';
import { addToCart, loadCartFromLocalStorage } from "../Cart/CartLogic";
import CartSVG from '../Resources/Cart.svg';
import SaleSVG from '../Resources/Sale.svg';
import { useLanguage } from "../LanguageContext";
import { useLocation } from "react-router";
import callApi from '../BackendCall/BackedCall';

import Handle404 from '../404Handle/404';

function ProductPage ({setCartItemCount, productList}){

    const [Description, setDescription] = useState('');
    const [product, setProduct] = useState(null);
    const {language} = useLanguage();
    const lang = require(`../languages/${language}.json`);
    const PPlang = lang.productPage;

    const [notFound, setNotFound] = useState(false);

    const location = useLocation();

    function handleAddToCart(product){
        addToCart(product);
        setCartItemCount(loadCartFromLocalStorage().length);
    }

    useEffect(() => {
        window.scrollTo(0,0)
        const searchParams = new URLSearchParams(location.search);
        const productId = searchParams.get('id');
        const numID = parseInt(productId);
        async function getProduct(id){
            const response = await callApi('/products/getProduct.php', 'POST', {lang: language, id: id});
            if(response.error){
                console.error('Error fetching product:', response.error);
            }else if(response.data.product){
                setProduct(response.data.product);
                setDescription(response.data.description);
            }else{
                console.log(response.data);
                console.error('Error fetching product:', 'NO PRODUCT WITH THAT ID');
                setNotFound(true);
            }
        }
        if (numID) getProduct(numID);
        else{
            setNotFound(true);
        }
    },[location,language]);

    function decodeSetContents(){
        let re = "";
        product.Contents.forEach(el => {
            productList.forEach(prod => {
                if(el === prod.id){
                    re += `${prod.name}${product.Contents[product.Contents.length - 1] === el? '' : ', '}`;
                }
            })
        })
        return re;
    }

    if(notFound)
        return <Handle404 />
    else if(product)
        return(
            <div className="product-page">
                <header>
                    <h1>
                        <img className={product.sale? "sale-product-page" : "sale-product-page hidden"} src={SaleSVG} alt="cart" />
                        {product.name}
                    </h1>
                    <img className="product-page-image" alt="product" src={`https://krasula-ns.com/backend/${product.imageURL}`} />
                </header>
                <div className="product-page-info">
                    
                    <h3 className="product-page-price">
                        {PPlang.price} <span style={product.sale? {textDecoration: 'line-through'} : {}}>{(Math.round(product.price * 100) / 100).toFixed(2)}RSD</span>
                    </h3>
                    <h3 className={product.sale? "product-page-price" : "product-page-price hidden"}>
                        {PPlang.salePrice} {product.sale? `${(Math.round(product.salePrice * 100) / 100).toFixed(2)}RSD` : ''}
                    </h3>
                    <button onClick={() => handleAddToCart(product)} className="product-page-add-to-cart-button">
                        {PPlang.addToCart}
                        <img className="product-page-cart-img" alt="cart-button-svg" src={CartSVG} />
                    </button>
                    <h3>{PPlang.contentTitle}</h3>
                    <p>
                        {product.set === '1'? decodeSetContents() : product.Contents}
                    </p>
                    <h3>{PPlang.descTitle}</h3>
                    <p>
                        {Description}
                    </p>
                </div>
            </div>
        )
    else return(
        <div style={{minHeght: '600px'}} className="product-page">
            
        </div>
    )
}

export default ProductPage;
import React from "react";

import "./MenuButton.css"

function MenuButton( props ){

    const HandleClick = () => {
        props.setShowMenu(!props.ShowMenu);
    }

    return(
        <button 
            className={props.ShowMenu? "menu-burger-button selected" : "menu-burger-button"}
            onClick={HandleClick}
        >
            <div className="button-line"></div>
            <div className="button-line"></div>
            <div className="button-line"></div>
        </button>
    )
}
export default MenuButton;
import React, { useEffect, useState } from "react";

import './ShopList.css';
import Product from "../../Components/Product";
import LazyLoadingProduct from "../../Components/LazyLoadingProduct";
import Sorter from "../../SortingLogic/SortingLogic";

function ShopList({Products, setCartItemCount, loading}){

    const [sortedProducts, setSortedProducts] = useState(Products);

    useEffect(()=>{
        const newProducts = Sorter.sortBySale(Products);
        setSortedProducts(newProducts);
    },[Products])

    if(sortedProducts.length !== 0){
        return (
            <div className={"shop-product-list "}>
                {loading? (
                    sortedProducts.map((el,index) => (
                        <LazyLoadingProduct key={index} index={index}/>)
                    )
                ) : (
                    sortedProducts.map((el,index) => (
                        <Product product={el} setCartItemCount={setCartItemCount} index={index} key={`product${index}`}/>)
                    )
                )}
            </div>
        )
    }else{
        return (<></>)
    }
}

export default ShopList;
import React from "react";

import './CartButton.css';

import CartSVG from '../Resources/Cart.svg';

function CartButton(props){

    return (
        <button className="cart-button" onClick={props.ToggleCart}>
            <img src={CartSVG}  alt='cart-ico'/>
            <p className={props.cartItemCount > 0? "item-count" : "item-count hidden"}>{props.cartItemCount}</p>
        </button>
    )
}

export default CartButton;
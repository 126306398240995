import React, {useRef, useEffect, useState} from "react";

import './NavBar.css';

import MenuButton from "./MenuButton";

import { Link } from "react-router-dom";
import CartButton from "./CartButton";

import { useLanguage } from '../LanguageContext';

import Logo from "../Resources/Krasula LOGO.png";

function NavBar(props){

    const [ShowMenu, setShowMenu] = useState(false);
    const [BtnSelected, setBtnSelected] = useState('');

    const NavRef = useRef(null);
    const NavTop = useRef(null);
    const LogoRef = useRef(null);
    const NavBarMain = useRef(null);

    const { language } = useLanguage();
    
    
    useEffect(()=>{
        if(NavRef.current && NavTop.current){
            NavRef.current.style.height = ShowMenu? `${NavRef.current.scrollHeight}px` : '0px';
            let NavButtonsOffset = NavTop.current.offsetHeight;
            NavRef.current.style.top = `${NavButtonsOffset}px`;
        }
    }, [ShowMenu])
    
    useEffect(() => {
        const location = window.location.pathname;
        setBtnSelected(location);
    }, [])
    
    useEffect(() => {
        const handleresize = () => {
            setShowMenu(false);
        }
        const handleScroll = () => {
            if(LogoRef.current){
                if(window.scrollY < 10){
                    LogoRef.current.style.height = '60px';
                    setShowMenu(false);
                }
                else{
                    LogoRef.current.style.height = '50px';
                    setShowMenu(false);
                }
            }
            if(NavBarMain.current){
                let opacity = (100-window.scrollY)/100;
                NavBarMain.current.style.backgroundColor = `rgba(255, 235, 205, ${opacity > 0? opacity : 0})`;
            }
        }
        window.addEventListener('resize',handleresize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.addEventListener('resize', handleresize);
            window.addEventListener('scroll', handleScroll);
        };
    },[])

    function handleButtonClick(route){
        setBtnSelected(route);
        setShowMenu(false);
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    const lang = require(`../languages/${language}.json`);
    
    return(
        <div ref={NavBarMain} className="nav-bar">
            <section ref={NavTop} className="nav-button-section">
                <div className="logo-burger-container">
                    <img ref={LogoRef} src={Logo} alt="logo" className="nav-logo" />
                    <MenuButton ShowMenu={ShowMenu} setShowMenu={setShowMenu} />
                </div>
                <CartButton ToggleCart={props.ToggleCart} cartItemCount={props.cartItemCount}/>
            </section>
            <nav ref={NavRef} className={ShowMenu? "nav-bar-buttons" : "nav-bar-buttons hidden"}>
                {Object.entries(lang.paths).map(([key, el]) => (
                    <Link
                        key={`link-key-${key}`}
                        className={BtnSelected === el.path ? 'selected' : ''}
                        onClick={() => handleButtonClick(el.path)}
                        to={el.path}
                    >
                        {el.label}
                    </Link>
                ))}
            </nav>
        </div>
    )
}
export default NavBar;
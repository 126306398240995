import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../LanguageContext";
import SerbiaFlag from "../Resources/serbiaFlag.svg";
import BritishFlag from "../Resources/britishFlag.svg";
import Arrow from "../Resources/downArrow.svg";

import './LanguagePick.css';

function LanguagePick () {

    const {language, changeLanguage} = useLanguage();

    const menuRef = useRef(null);
    const topRef = useRef(null)

    const [menuOpen, setMenuOpen] = useState(false);

    const handleOptionChange = (lang) => {
        setMenuOpen(false);
        changeLanguage(lang);
    };

    const handleMenuState = () => {
        setMenuOpen(!menuOpen);
    }

    useEffect(()=>{
        if(menuRef != null){
            if(menuRef.current && topRef.current){
                menuRef.current.style.height = menuOpen? `${menuRef.current.scrollHeight}px` : '0px';
                let offset = topRef.current.offsetHeight;
                menuRef.current.style.top = offset + 'px';
            }
        }
    },[menuOpen])

    const lang = require(`../languages/${language}.json`);

    const langList = {
        srb: {
            text: 'SPRSKI', 
            flag: SerbiaFlag
        },
        eng: {
            text: 'ENGLISH', 
            flag: BritishFlag
        }
    }
    
    return (
        <div className="lang-pick">
            <div ref={topRef} className="lang-pick-top">
                <label htmlFor="dropdown"><strong>{lang.LanguagePick}:</strong></label>
                <img className="flag" src={langList[language].flag} alt="flag" />
                <button className="lang-button" onClick={handleMenuState}><img src={Arrow} alt="arrow" className={menuOpen? "arrow open" : "arrow"}/></button>
            </div>
            <div className="dropdown-menu" id="dropdown" ref={menuRef}>
                <button className="lang-button" onClick={() => handleOptionChange('srb')} >
                    Srpski
                    <img className="flag" src={SerbiaFlag} alt="flag" />
                </button>
                <button className="lang-button" onClick={() => handleOptionChange('eng')} >
                    English
                    <img className="flag" src={BritishFlag} alt="flag" />
                </button>
            </div>
        </div>
    );
}

export default LanguagePick;
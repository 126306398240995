import React, { useState, useEffect, useRef } from "react";

import "./LazyLoadingProduct.css";

function LazyLoadingProduct({ index }) {
    const [widthA, setwidthA] = useState(0);
    const [widthB, setwidthB] = useState(0);
    const parentARef = useRef(null);
    const parentBRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (parentARef.current) {
                const parentWidth = parentARef.current.offsetWidth;
                setwidthA(prevwidth => prevwidth +parentWidth/10 > parentWidth? parentWidth : prevwidth + parentWidth/3);
            }
            if (parentBRef.current) {
                const parentWidth = parentBRef.current.offsetWidth;
                setwidthB(prevwidth => prevwidth + parentWidth/10 > parentWidth? parentWidth : prevwidth + parentWidth/3);
            }
        }, 100);

        return () => clearInterval(interval);
    
    }, [parentARef, parentBRef]);

    return (
        <div className="lazy-loading-product" key={`${index}lazy`}>
            <div className="lazy-loading-product-info">
                <div className="lazy-loading-product-name" ref={parentARef}>
                    <div className="overlay" style={{ width: `${widthA}px` }}></div>
                </div>
                <div className="lazy-loading-product-price" ref={parentBRef}>
                    <div className="overlay" style={{ width: `${widthB}px` }}></div>
                </div>
            </div>
        </div>
    );
}

export default LazyLoadingProduct;

import React from "react"
import { Link } from "react-router-dom";
import { loadCartFromLocalStorage, addToCart } from "../Cart/CartLogic";
import "./Product.css"

import CartSVG from "../Resources/Cart.svg";
import SaleSVG from "../Resources/Sale.svg";
import { useLanguage } from "../LanguageContext";

function Product({product, setCartItemCount, index}){

    const {language} = useLanguage();
    const lang = require(`../languages/${language}`);
    const productLang = lang.product

    function handleAddToCart(event, item){
        event.preventDefault();
        addToCart(item);
        setCartItemCount(loadCartFromLocalStorage().length)
    }

    return(
        <Link to={`/Product?id=${product.id}`} className="shop-product" key={`${index}shopproduct`}>
            <img className="shop-product-cover" alt="product-cover" src={`https://krasula-ns.com/backend/${product.imageURL}`}/>
            <div className="shop-product-info">
                <p className="prod-name">{product.name}{product.set !== '1'? ` - ${product.size}` : ''}</p>
                <p className={"shop-product-salePrice"}>{(Math.round((product.sale? product.salePrice : product.price) * 100) / 100).toFixed(2)}RSD
                </p>
                <p className={product.sale? "shop-product-price sale" : "shop-product-price hidden"}>
                    {(Math.round(product.price * 100) / 100).toFixed(2)}RSD
                </p>
            </div>
            <img className={product.sale? "sale-shop-product" : "sale-shop-product hidden"} src={SaleSVG} alt="cart" />
            <button onClick={(ev)=> handleAddToCart(ev,product)}>
                {productLang.addToCart}
                <img className="cart-shop-product" src={CartSVG} alt="cart" />
            </button>
        </Link>
    )

}

export default Product;
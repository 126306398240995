import React , {useEffect, useState} from "react";

import './Blog.css';

import BlogCover from '../Resources/Blog cover.png';
import { useLanguage } from "../LanguageContext";

import { useLocation } from "react-router-dom";
import BlogTopic from "./BlogTopic";
import Handle404 from "../404Handle/404";
import BlogTopicCard from "./BlogTopicCard";

function Blog({TopicList}){

    const {language} = useLanguage();
    const lang = require(`../languages/${language}.json`);
    const blogLang = lang.blog;

    const location = useLocation();

    const [notFound, setNotFound] = useState(false);
    const [found, setFound] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        const searchParams = new URLSearchParams(location.search);
        const topicId = searchParams.get('id');
        const numID = parseInt(topicId);
        
        async function getTopic(id) {
            const topic = TopicList.find(el => el.id === id);
            return topic;
        }
    
        if (numID) {
            getTopic(numID)
                .then(topic => {
                    setFound(topic);
                })
                .catch(error => {
                    console.error('Error fetching topic:', error);
                    setNotFound(true);
                });
        } else {
            setFound(null);
        }
    }, [location, TopicList]);
    

    if(notFound) return <Handle404 />
    else if(found) return <BlogTopic topic={found} />
    else return (
        <div className="blog-page">
            <div className="blog-cover-container">
                <h1 className="blog-title">
                    {blogLang.title}
                </h1>
                <img className="blog-cover" src={BlogCover} alt="logo" />
            </div>
            <h3 className="blog-title">
                {blogLang.introduction}
            </h3>
            <h2 className="blog-title">
                {blogLang.listLabel}
            </h2>
            <section className="topics-section">
            {
                TopicList && (
                    TopicList.map((el,index) => (
                        <BlogTopicCard topic={el} key={index} readtime={blogLang.readingTime} />
                    ))
                )
            }
            </section>
           

        </div>
    )
}

export default Blog;
export const addToCart = (product) => {
    let cartProduct = {};
    cartProduct.price = (parseInt(product.sale? product.salePrice : product.price)).toFixed(2);
    cartProduct.name = product.name;
    cartProduct.sale = product.sale;
    cartProduct.commissionFee = product.commissionFee;
    console.log(`Product commission fee is: ${cartProduct.commissionFee}`);
    cartProduct.quantity = 1;
    cartProduct.promo = null;
    let productFound = false;
    let cart = loadCartFromLocalStorage();
    cart.forEach(el => {
        if(el.name === cartProduct.name && !productFound){
            el.quantity += 1;
            productFound = true;
        }
    });
    let updatedCart;
    if(productFound){
        updatedCart = cart;
    }else{
        updatedCart = [...cart, cartProduct];
    }
    const JSONString = JSON.stringify(updatedCart);
    localStorage.setItem('krasula-cart', JSONString);
    return updatedCart;
};
  
export const removeFromCart = (product) => {
    const cartItems = loadCartFromLocalStorage();
    const updatedCart = cartItems.filter(item => item.name !== product.name);
    const JSONString = JSON.stringify(updatedCart);
    localStorage.setItem('krasula-cart', JSONString);
    return updatedCart;
};

export const clearCart = () => {
    localStorage.removeItem('krasula-cart');
}

export const modifyQuatity = (product, dir) =>{
    const cartItems = loadCartFromLocalStorage();
    product.quantity += dir === 'up'? 1 : -1;
    if(product.quantity === 0){
        removeFromCart(product);
    }else{
        cartItems.forEach(el => {
            if(product.name === el.name){
                el.quantity = product.quantity;
            }
        })
        const JSONString = JSON.stringify(cartItems);
        localStorage.setItem('krasula-cart', JSONString);
    }
}
  
export const loadCartFromLocalStorage = () => {
    const cartData = localStorage.getItem('krasula-cart');
    return cartData ? JSON.parse(cartData) : [];
};
  
import React from "react";

import './ProductList.css'

import Product from "../../Components/Product";
import LazyLoadingProduct from "../../Components/LazyLoadingProduct";

function ProductListH ({ title, products, setCartItemCount, loading}) {

    return(
        <div className="product-list-h">
            <h2>{title}</h2>
            <div className="list">
            {loading? (
                <>
                    <LazyLoadingProduct index={1} />
                    <LazyLoadingProduct index={2} />
                    <LazyLoadingProduct index={3} />
                </>
            ) : products.length > 0? (
                products.map((el,index) => (
                    <Product key={`${title}p${index}`} product={el} setCartItemCount={setCartItemCount} />
                ))
            ): (
                <p>Nema proizvoda trenutno</p>
            )}
            </div>
        </div>
    )
}

export default ProductListH;
import React from "react";

import './AboutMe.css';

import Image1 from '../Resources/AboutSlika1.jpg';
import Image2 from '../Resources/AboutSlika2.jpg';
import Image3 from '../Resources/AboutSlika3.jpg';
import { useLanguage } from "../LanguageContext";

function AboutMe () {

    const {language} = useLanguage();

    const lang = require(`../languages/${language}.json`);

    const content = lang.about;

    return (
        <div className="page-about">
            <div className="about-cover">
                <img className="cover-img" src={Image1} alt="about-img"/>
                <img className="cover-img" src={Image2} alt="about-img"/>
            </div>
            <h1>{content.heading}</h1>
            {
                content.paragraphs.map((el,key) => (
                    el.type === "p"? (
                        <p className="paragraph">{el.content}</p>
                        ) : el.type === 'div'? (
                        <div className="paragraph">
                            {el.content.map((divel,key) =>(
                                divel.type === 'p'? (
                                    divel.content
                                ) : divel.type === 'ul'? (
                                    <ul>
                                        {divel.content.map((li, key) => (
                                            <li>{li}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <></>
                                )
                            ))}
                        </div>
                    ) : (
                        <img className="about-img" src={Image3} alt="about-img"/>
                    )
                ))
            }
        </div>
    )
}

export default AboutMe;
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import './Cart.css';

import { clearCart, loadCartFromLocalStorage, modifyQuatity, removeFromCart } from "./CartLogic";

import CartSVG from '../Resources/Cart.svg';
import ExitSVG from '../Resources/Exit.svg';
import Logo from '../Resources/Krasula LOGO.png';
import BinSVG from '../Resources/Bin.svg';
import ApproveSVG from '../Resources/Approve.svg';
import GearSVG from '../Resources/Gear.svg';

import {useLanguage} from "../LanguageContext";



function Cart ({Shown, ToggleCart, setCartItemCount, productList}) {

    const [CartList, setCartList] = useState([]);
    const [CartTotal, setCartTotal] = useState(0);
    const [Checkout, setCheckout] = useState(false);
    const [OrderForm, setOrderForm] = useState({fname: '', lname: '', tel: '', address: '', city: '', email: '', order: [], totalSale: '', paymentMethod: ''});
    const [EmailingStages, setEmailingStages] = useState('');
    const [UserPromoCode, setUserPromoCode] = useState('');
    const [ActivePromoCodes, setActivePromoCodes] = useState([]);
    const [ErrorMSG, setErrorMSG] = useState('');
    
    //Language setup
    const {language} = useLanguage();
    const lang = require(`../languages/${language}.json`);
    const cartLang = lang.cart;

    const CartRef = useRef(null);

    React.useEffect(() => {
        if(Shown){
            setupCart();
        }
    }, [Shown]);

    function handleRemoveItem(el){
        removeFromCart(el);
        setupCart();
    }

    function handleModifyQuantity(el, dir){
        modifyQuatity(el,dir)
        setupCart();
    }

    function handleUserInput(e) {
        const { name, value } = e.target;
        setOrderForm(prevState => ({ ...prevState, [name]: value }));
    }

    function  handlePromoInput() {
        var activePromos = [...ActivePromoCodes];
        const formData = new FormData();
        formData.append('code', UserPromoCode);

        fetch('https://krasula-ns.com/backend/promo/checkPromo.php', {
            method: 'POST',
            body: formData
        })
        .then(response => {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                return response.json();
            } else {
                return response.text();
            }
        })
        .then(data => {
            if(data.response){
                let alreadyAdded = false;
                activePromos.forEach(el => {
                    if(el.code === data.msg.code){
                        alreadyAdded = true;
                    }
                })
                if(alreadyAdded){
                    setUserPromoCode(cartLang.promoCodeResponse.hasCode)
                }else{
                    activePromos.push(data.msg);
                    setUserPromoCode(cartLang.promoCodeResponse.success);
                } 
                    
            }else{
                if(data.msg === 'NoMatch'){
                    setUserPromoCode(cartLang.promoCodeResponse.invalid);
                }else if(data.msg === 'DecodeError'){
                    setUserPromoCode(cartLang.promoCodeResponse.server);
                }else if(data.msg === 'FileError'){
                    setUserPromoCode(cartLang.promoCodeResponse.server);
                }else if(data.msg === 'MethodError'){
                    setUserPromoCode(cartLang.promoCodeResponse.server);
                }
            }
        })
        .catch(error => {
            console.error(error);
            setUserPromoCode(cartLang.promoCodeResponse.other);
        });
        setActivePromoCodes(activePromos);
    }

    useEffect(()=>{
        setupCart();
    }, [ActivePromoCodes])

    function setupCart(){
        var cartproducts = loadCartFromLocalStorage();
        setCartItemCount(cartproducts.length);
        let total = 0;
        cartproducts.forEach(product => {
            if(!product.sale){
                //if product is not on sale check active promos
                ActivePromoCodes.forEach(promo => {
                    if(promo.productsAffected.length > 0){
                        //if active promo has list of affected prudcts go trough that list
                        promo.productsAffected.forEach(item => {
                            if(item === product.id){
                                //Check if product has promo and update the discount based on the discount size
                                if(product.promo){
                                    if(1 - product.promo.discount < 1 - promo.discount);
                                    product.promo = promo;
                                }else{
                                    product.promo = promo;
                                }
                            }
                        })
                    }else{
                        //if no affected product list is found then it mean all prpducts are affected
                        //Check if product has promo and update the discount based on the discount size
                        if(product.promo){
                            if(1 - product.promo.discount < 1 - promo.discount){
                                product.promo = promo;
                            }
                        }else{
                            product.promo = promo;
                        }
                    }
                });
            }
            //Update cart product list based on the active promos, price and quantity
            const price = product.promo? parseInt(product.price) * (product.promo.discount) : parseInt(product.price);
            const quantity = parseInt(product.quantity);
            total += quantity * price;
        });
        //Set the variables
        setCartTotal(total);
        setOrderForm(prevState => ({ ...prevState, totalSale: total }));
        setOrderForm(prevState => ({ ...prevState, order: cartproducts }));
        setCartList(cartproducts);
    }

    function sendOrder() {
        setEmailingStages({sending: true, sent:false, failed:false});

        const formData = new FormData();
        formData.append('fname', OrderForm.fname);
        formData.append('lname', OrderForm.lname);
        formData.append('tel', OrderForm.tel);
        formData.append('address', OrderForm.address);
        formData.append('city', OrderForm.city);
        formData.append('email', OrderForm.email);
        formData.append('order', JSON.stringify(OrderForm.order));
        formData.append('totalSale', OrderForm.totalSale);
        formData.append('paymentMethod', OrderForm.paymentMethod);

        fetch('https://krasula-ns.com/backend/Order.php', {
            method: 'POST',
            body: formData
        })
        .then(response => {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                return response.json();
            } else {
                return response.text();
            }
        })
        .then(data => {
            if(data.trim() === 'success'){
                setEmailingStages({sending: false, sent:true, failed:false});
                clearCart();
            }else if(data.trim() === 'flname error'){
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.fnameError);
            }else if(data.trim() === 'address error'){
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.addressError);
            }else if(data.trim() === 'city error'){
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.addressError);
            }else if(data.trim() === 'tel error'){
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.phoneError);
            }else if(data.trim() === 'order error'){
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.orderError);
            }else if(data.trim() === 'total error'){
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.orderTotal);
            }else if(data.trim() === 'sending error'){
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.sendingError);
            }else if(data.trim() === 'paymentMethod error'){
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.paymentMethodError);
            }else{
                setEmailingStages({sending: false, sent:false, failed:true});
                setErrorMSG(cartLang.orderResponse.other);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setEmailingStages({sending: false, sent:false, failed:true});
            setErrorMSG(cartLang.orderResponse.other);
        });
        setOrderForm({fname: '', lname: '', tel: '', address: '', city: '', email: '', order: [], totalSale: '', paymentMethod: ''});
        setupCart();
    }

    function handleNotificationExit(){
        setCheckout(false);
        setEmailingStages({sending: false, sent:false, failed:false});
    }

    function handleShopOpen(){
        ToggleCart();
        window.scrollTo(0, 0);
    }

    return (
        <div ref={CartRef} className={Shown? "cart-page" : "cart-page hidden"}>
            <button className="cart-close" onClick={ToggleCart}>
                <img src={ExitSVG} alt="exit-svg" />
            </button>
            <img className="cart-logo" src={Logo} alt="logo" />
            
            <div className={Checkout? "cart hidden" : "cart"}>
                    <img style={{width: '60px'}} src={CartSVG} alt="cart-icon"/>
                <h3 style={{letterSpacing: '3px'}}><strong>{cartLang.cartTitle}</strong></h3>
                {CartList.length !== 0? (
                    <div className="cart-has-items">
                    {CartList.map((el,index) => (
                        <div className="cart-product" key={`product${index}`}>
                            <p>{el.name}</p>
                            <p>
                                {el.quantity} 
                                x 
                                {
                                    el.promo? 
                                    ((Math.round(el.price * 100) / 100)*el.promo.discount).toFixed(2) 
                                    : 
                                    (Math.round(el.price * 100) / 100).toFixed(2) 
                                }
                                RSD
                                </p>
                            <p>
                                {
                                    el.promo?
                                    ((Math.round(el.price * el.quantity * 100) / 100)*el.promo.discount).toFixed(2) 
                                    : 
                                    (Math.round(el.price * el.quantity * 100) / 100).toFixed(2)}RSD</p>
                            <div className="cart-product-buttons">
                                <button onClick={() => handleModifyQuantity(el,'down')}>-</button>
                                <button onClick={() => handleModifyQuantity(el,'up')}>+</button>
                                <button onClick={() => handleRemoveItem(el)}>
                                    <img src={BinSVG} alt="bin-remove" />
                                </button>
                            </div>
                        </div>
                    ))}
                        <p className="cart-total-show">{cartLang.cartValue} {(Math.round(CartTotal * 100) / 100).toFixed(2)}RSD</p> 
                        <p className="cart-pricing-info">
                            {cartLang.noticeOrder}
                        </p>
                        <button className="in-cart-button" onClick={() => setCheckout(true)}>
                            {cartLang.finishOrder}
                            <img style={{width: '20px'}} src={ApproveSVG} alt="approve-svg" />
                        </button>
                    </div>
                ) : (
                    <div className="empty-cart-message">
                        <p>{cartLang.cartEmpty}</p>
                        <Link className="in-cart-button" to="/Shop" onClick={handleShopOpen}>{cartLang.shopNow}</Link>
                    </div>
                )}
            </div>

            <div className="cart-middle"></div>
            
            <div className="checkout">
                <p><strong>{cartLang.promo.notice1}</strong></p>
                
                <p><strong>{cartLang.promo.notice2}</strong></p>
                <p><strong>{cartLang.promo.notice3}</strong></p>
                <p><strong>{cartLang.promo.notice4}</strong></p>
                <p>
                    {cartLang.promo.activeLabel}
                </p>
                <ul style={{width: '90%', alignItems: 'flex-start'}} className="active-promo-list">
                    {ActivePromoCodes.map((el,index) => (
                        <li key={`${index}kod`}>
                            {cartLang.promo.activeCode.code} {el.code}
                            <ul>
                                <li> {cartLang.promo.activeCode.code} {(1 - el.discount).toFixed(2) * 100}%</li>
                                <li>    
                                    {cartLang.promo.activeCode.affected}
                                    <ul>
                                        {el.productsAffected.length > 0? (
                                            el.productsAffected.map((affected,index) => (
                                                <li key={`Afeected-Product${index}`}>
                                                    {productList.map((prod, key) => (
                                                        affected === prod.id? (
                                                            prod.name
                                                        ):(
                                                            <></>
                                                        )
                                                    ))}
                                                </li>
                                                )
                                            )
                                        ) : (
                                            <li>{cartLang.promo.activeCode.affectedAll}</li>
                                        )
                                        }
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    ))}
                </ul>
                <div className="promo-input">
                    <input value={UserPromoCode} onChange={(e) => setUserPromoCode(e.target.value)} type="text" id="promo" name="promo" placeholder="KRNS1234"/>
                    <button onClick={handlePromoInput} className="in-cart-button" id="promoButton">{cartLang.promo.addCodeBtn}</button>
                </div>
            </div>
            
            <div className={Checkout? "checkout" : " checkout hidden"}>
                <h3 style={{letterSpacing: '3px'}}><strong>{cartLang.chekout}</strong></h3>
                {EmailingStages.sending? (
                    <div className="notification-div">
                        <h3>{cartLang.orderStagesInfo.sending}</h3>
                        <img className="notification-icon rotate" src={GearSVG} alt="notification-icon" />
                        <button onClick={()=>handleNotificationExit()} className={"order-button"}type="button">{cartLang.backButton}</button>
                    </div>
                ) : EmailingStages.sent? (
                    <div className="notification-div">
                        <h3>{cartLang.orderStagesInfo.success}</h3>
                        <img className="notification-icon" src={ApproveSVG} alt="notification-icon" />
                        <button onClick={()=>handleNotificationExit()} className={"order-button"}type="button">{cartLang.backButton}</button>
                    </div>
                ) : EmailingStages.failed? (
                    <div className="notification-div">
                        <h3>{ErrorMSG}</h3>
                        <img className="notification-icon" src={ExitSVG} alt="notification-icon" />
                        <button onClick={()=>handleNotificationExit()} className={"order-button"}type="button">{cartLang.backButton}</button>    
                    </div>
                ) : (
                    <form className="order-form">
                        <button onClick={()=>setCheckout(false)} className={"order-button"}type="button">{cartLang.backButton}</button>
                        <label htmlFor="payment-method">
                            {cartLang.paymentMethodLabel}
                        </label>
                        <select name="paymentMethod" id="payment-method" value={OrderForm.paymentMethod} onChange={handleUserInput} className="payment-selection">
                            <option value="choose">
                                {cartLang.paymentMethods.label}
                            </option>
                            <option value="on-deliver">
                                {cartLang.paymentMethods.onDelivery}
                            </option>
                            <option value="bank-transfer" disabled>
                                {cartLang.paymentMethods.bankTransfer}
                            </option>
                        </select>
                        <p style={{alignSelf: 'flex-start'}}><strong>
                            {cartLang.paymentMethods.notice} 
                        </strong></p>
                        <label htmlFor="fname" >{
                            cartLang.form.name
                        }</label>
                        <input onChange={handleUserInput} value={OrderForm.fname} name="fname" id="fname" type="fname" />
                        
                        <label htmlFor="lname" >
                            {cartLang.form.lastName}
                        </label>
                        <input onChange={handleUserInput} value={OrderForm.lname} name="lname" id="lname" type="lname" />
                            
                        <label htmlFor="tel" >
                            {cartLang.form.phone}
                        </label>
                        <input onChange={handleUserInput} value={OrderForm.tel} name="tel" id="tel" type="tel" />
                            
                        <label htmlFor="address" >
                            {cartLang.form.address}
                        </label>
                        <input onChange={handleUserInput} value={OrderForm.address} name="address" id="address" type="address" />
                            
                        <label htmlFor="city" >
                            {cartLang.form.city}
                        </label>
                        <input onChange={handleUserInput} value={OrderForm.city} name="city" id="city"  type="city" />
                            
                        <label htmlFor="order-email" >
                            {cartLang.form.email}
                        </label>
                        <input onChange={handleUserInput} value={OrderForm.email} name="email" id="order-email" type="email" />
                            
                        <button onClick={sendOrder} className={"order-button"} type="button">
                            {cartLang.form.button}
                        </button>

                    </form>
                )}
            </div>
            <div className="cart-bottom"></div>
        </div>
    )
}

export default Cart;
import React from "react";

import Logo from '../Resources/Krasula LOGO.png'

import './Footer.css';
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import SocialIconList from "./socailIcons";

function Footer({products, LatestBlog}){

    const {language} = useLanguage();
    const lang = require(`../languages/${language}.json`);
    const footerLang = lang.footer;

    return (
        <footer>
            <section className="footer-links">
                <div className="footer-link-group">
                    <p className="footer-link-group-p">{footerLang.mentionsLabel}</p>
                    <a className="footer-link-group-link" href="https://jollywoman.com/100-prirodno-i-rucno-radjeno-krasula-pet-balzma-za-zastitu-i-negu-sapica-vasih-ljubimaca/" target="_blank" rel="noreferrer">
                        <img src="https://jollywoman.com/wp-content/themes/jollywoman/img/favicon.ico" alt="icon" />
                        {footerLang.jollyWoman}
                    </a>
                </div>
                <div className="footer-link-group">
                    <p className="footer-link-group-p">{footerLang.productListLabel}</p>
                    {products? products.map((el,index) => (
                        <Link className="footer-link-group-link" key={`footerp${index}`} to={`/Product?id=${el.id}`} >
                            {el.name}
                        </Link>
                    )) : <></>}
                </div>
                <div className="footer-link-group">
                    <p className="footer-link-group-p">{footerLang.blogLabel}</p>
                    <Link className="footer-link-group-link" to={`/Blog?id=${LatestBlog.id}`}>{LatestBlog.title}</Link>
                </div>
            </section>
            <div className="footer-link-group no-border">
                
                <SocialIconList fill={"red"}/>
                <img className="footer-logo" src={Logo} alt="logo" />
                <p>{footerLang.websiteDev}</p>
                <a target="_blank" rel="noreferrer" href="https://www.vukmaric.com/" className="footer-credit">
                    <img className="credit-img" src={'https://vukmaric.com/favicon.ico'} alt="insta" />
                </a>
            </div>
            <p style={{width: '100%'}}>© KRASULA NS 2023</p>
        </footer>
    )
}

export default Footer; 
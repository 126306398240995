import React from "react";

import "./BlogTopicCard.css";

import BlogCover from "../Resources/Blog cover.png";

function BlogTopicCard({topic, readtime}){
    return (
        <a href={`/Blog?id=${topic.id}`} className="blog-topic-card">
            <img src={BlogCover}  />
            <h2>{topic.title}</h2>
            <h3>{topic.date}</h3>
            <h3>{readtime} {topic.read_time}</h3>
        </a>
    )
}

export default BlogTopicCard;
import React, { useEffect, useState, useRef } from "react";

import './Shop.css'
import ShopList from "./components/ShopList";
import { useLanguage } from "../LanguageContext";

import ShopCover from "../Resources/Krasula Shop Cover.png";
import ArrowSVG from "../Resources/downArrow.svg";

function Shop({Products ,setCartItemCount}){

    const [Category, setCategory] = useState(null);
    const [HumanProducts, setHumanProducts] = useState([]);
    const [PetProducts, setPetProducts] = useState([]);
    const [BoxProducts, setBoxProducts] = useState([]);

    const [loading, setLoading] = useState(true);
    
    const {language} = useLanguage();
    const lang = require(`../languages/${language}.json`);
    const shopLang = lang.shop;

    const categoryRef = useRef(null);

    useEffect(()=>{
        if(Products){
            var pet = [];
            var human = [];
            var box = [];
            Products.forEach(el => {
                if(el.pet){
                    pet.push(el);
                }
                if(el.set === '1'){
                    box.push(el);
                }
                if(el.human){
                    human.push(el);
                }
            });
            setHumanProducts(human);
            setPetProducts(pet);
            setBoxProducts(box);
        }
    }, [Products])

    function handleCategorySelection(category){
        setLoading(true);
        setCategory(category);
    }

    useEffect(() => {
        if (categoryRef.current) {
            window.scrollTo({
                top: categoryRef.current.offsetTop,
                behavior: 'smooth'
            });
        }else{
            console.log('NO REF!')
        }
    }, [Category]);

    useEffect(() => {
        const waitLoading = async () => {
            await new Promise(resolve => setTimeout(resolve, 500));
            setLoading(false);
        }
    
        if (loading) {
            waitLoading();
        }
    }, [loading]);


    return(
        <div className="shop-page">
            <div  className="shop-cover">
                <img className="cover-img" src={ShopCover} alt="shop-cover" />
                <h1>{shopLang.title}</h1>
                {
                Category !== null &&
                <img onClick={()=> window.scrollTo({top: categoryRef.current.offsetTop, behavior: 'smooth'})} className="shop-arrow-img" src={ArrowSVG} alt="arrow-down" />
                }
            </div>
            
            
            <section ref={categoryRef}
            style={Category? {position: 'relative', marginBottom: '10px'} : {position: "absolute", marginBottom: '0px'}} className="category-pick-nav">
                <h2 className="categories-title">{shopLang.categoriesTitle}</h2>
                <button onClick={() => handleCategorySelection('pet')} className={Category === 'pet'? 'selected' : '' }>{shopLang.pet}</button>
                <button onClick={() => handleCategorySelection('human')} className={Category === 'human'? 'selected' : '' }>{shopLang.human}</button>
                <button onClick={() => handleCategorySelection('box')} className={Category === 'box'? 'selected' : '' }>{shopLang.box}</button>
                <button onClick={() => handleCategorySelection('all')} className={Category === 'all'? 'selected' : '' }>{shopLang.all}</button>
            </section>
            
            <ShopList 
                loading={loading}
                setCartItemCount={setCartItemCount} 
                Products={Category === 'pet'? PetProducts : Category === 'human'? HumanProducts : Category === 'box'? BoxProducts : Category === 'all'? Products : []}
            />

        </div>
    )
}

export default Shop;